@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300&display=swap");
@font-face {
  font-family: YuMincho;
  src: local(Yu Mincho), local(YuMincho);
  font-weight: normal;
}
@keyframes headerSlideDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea,
select {
  margin: 0;
  font-size: 16px;
}

button {
  padding: 0;
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  transition: 0.3s ease 0s;
}
@media (min-width: 769px) {
  button img {
    transition: 0.3s ease 0s;
  }
  button[href]:hover {
    opacity: 0.7;
  }
  button[href]:hover img {
    opacity: 0.7;
  }
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset, img {
  border: 0;
}

img {
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: inherit;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

a, input { /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/ }

sup, sub {
  font-size: 63%;
}

sup {
  font-size: 75.5%;
  vertical-align: top;
}

sub {
  font-size: 75.5%;
  vertical-align: bottom;
}

hr {
  border: none;
  border-bottom: solid 1px #D9D9D9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  min-height: 100%;
  background: #fff;
  font-size: 1.6rem;
  font-family: "Noto Sans SC", sans-serif;
  line-height: 1.75;
  color: #2c2c2c;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
}
body:lang(en), body:lang(zh-cn), body:lang(zh-tw), body:lang(zh-hk) {
  font-family: "Noto Sans SC", sans-serif;
}
@media (max-width: 640px) {
  body {
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease 0s;
}
a[href^=tel] {
  color: inherit;
}
@media (min-width: 769px) {
  a img {
    transition: 0.3s ease 0s;
  }
  a[href]:hover {
    opacity: 0.7;
  }
  a[href]:hover img {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}

i {
  font-style: normal;
}

@media (max-width: 640px) {
  .only-pc {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .only-sp {
    display: none !important;
  }
}

.only-sp-land {
  display: none !important;
}
@media (max-width: 360px) {
  .only-sp-land {
    display: block !important;
  }
}

@media (min-width: 321px) {
  .only-ss {
    display: none !important;
  }
}

.is-required::after {
  content: "*";
  margin: 0 0.5em;
  color: #ED5454;
}

.cf::after {
  display: block;
  clear: both;
  content: "";
}

input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=date] {
  height: 32px;
  padding: 0 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}
input[type=text][size="60"], input[type=password][size="60"], input[type=email][size="60"], input[type=tel][size="60"], input[type=search][size="60"], input[type=date][size="60"] {
  width: 100%;
}
input[type=text]::placeholder, input[type=password]::placeholder, input[type=email]::placeholder, input[type=tel]::placeholder, input[type=search]::placeholder, input[type=date]::placeholder {
  color: #666;
}

textarea {
  width: 100%;
  min-height: 200px;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/compornent/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  padding: 0;
  margin-top: -20px;
  border: none;
  outline: none;
  z-index: 1;
  transition: 0.3s transform;
}
@media (max-width: 767px) {
  .slick-prev,
  .slick-next {
    height: 33px;
    width: 22px;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:hover,
.slick-next:hover {
  will-change: transform;
}
.c-slider-list .slick-prev,
.c-slider-list .slick-next {
  top: 0;
  margin-top: 65px;
}
@media (max-width: 961px) {
  .c-slider-list .slick-prev,
  .c-slider-list .slick-next {
    margin-top: 25px;
  }
}

.slick-prev {
  left: -8px;
}
@media (max-width: 767px) {
  .slick-prev {
    left: -13px;
  }
}
.slick-prev:hover {
  transform: translateX(-10px);
}
@media (max-width: 961px) {
  .slick-prev:hover {
    transform: translateX(0);
  }
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  background: no-repeat center right;
  background-size: cover;
}
[dir=rtl] .slick-prev:before {
  content: "→";
}
@media (max-width: 767px) {
  .c-thumb .slick-prev {
    left: -8px;
  }
}

.slick-next {
  right: -8px;
}
@media (max-width: 767px) {
  .slick-next {
    right: -13px;
  }
}
.slick-next:hover {
  transform: translateX(10px);
}
@media (max-width: 961px) {
  .slick-next:hover {
    transform: translateX(0);
  }
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  background: no-repeat center right;
  background-size: cover;
}
[dir=rtl] .slick-next:before {
  content: "←";
}
@media (max-width: 767px) {
  .c-thumb .slick-next {
    right: -8px;
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@font-face {
  font-family: "icomoon-lc-casual";
  src: url("../fonts/icomoon-lc-casual.ttf?747gor") format("truetype"), url("../fonts/icomoon-lc-casual.woff?747gor") format("woff"), url("../fonts/icomoon-lc-casual.svg?747gor#icomoon-lc-casual") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon-lc-casual" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\e90f";
}

.icon-angle_down:before {
  content: "\e900";
}

.icon-angle_left:before {
  content: "\e901";
}

.icon-angle_right:before {
  content: "\e902";
}

.icon-angle_up:before {
  content: "\e903";
}

.icon-arrow_right:before {
  content: "\e904";
}

.icon-cart:before {
  content: "\e905";
}

.icon-facebook:before {
  content: "\e906";
}

.icon-favorite:before {
  content: "\e907";
}

.icon-insta:before {
  content: "\e908";
}

.icon-line:before {
  content: "\e909";
}

.icon-mail:before {
  content: "\e90a";
}

.icon-ranking:before {
  content: "\e90b";
}

.icon-search:before {
  content: "\e90c";
}

.icon-triangle_down:before {
  content: "\e90d";
}

.icon-user:before {
  content: "\e90e";
}

.l-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background: #fff;
}
.l-header__hamburgerbtn {
  width: 50px;
  height: 22px;
  top: 0;
  cursor: pointer;
  text-align: center;
  position: relative;
  margin-left: 5rem;
}
.l-header__hamburgerbtn .icon_txt01 {
  position: absolute;
  top: 23px;
  font-size: 10px;
  color: #7C7C7C;
  right: 0;
}
@media (max-width: 768px) {
  .l-header__hamburgerbtn {
    margin-left: 1.4rem;
  }
}
.l-header__hamburgerbtn span {
  position: absolute;
  width: 100%;
  height: 2px;
  right: 0;
  background-color: #707070;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  transition: 0.5s all;
}
.l-header__hamburgerbtn span:nth-child(1) {
  top: 0;
}
.l-header__hamburgerbtn span:nth-child(2) {
  top: 13px;
  width: 35px;
}
.l-header__hamburgerbtn.active span:nth-child(1) {
  top: 11px;
  left: 0;
  width: 50px;
  -webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
@-webkit-keyframes active-hamburger-bar02 {
  100% {
    height: 0;
  }
}
@keyframes active-hamburger-bar02 {
  100% {
    height: 0;
  }
}
.l-header__hamburgerbtn.active span:nth-child(2) {
  top: 11px;
  width: 50px;
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  transform: rotate(20deg);
}
.l-header__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 10px 20px 10px 50px;
}
@media (max-width: 768px) {
  .l-header__info {
    padding: 5px 10px;
    min-height: initial;
    font-size: 1.4rem;
    line-height: 1.2;
  }
}
.l-header__contact {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding-left: 2em;
}
.l-header__contact span {
  vertical-align: bottom;
  line-height: 2.3rem;
}
@media (max-width: 768px) {
  .l-header__contact span {
    display: none;
  }
}
.l-header .icon-mail {
  margin-right: 0.3em;
  font-size: 2.3rem;
  vertical-align: bottom;
  line-height: 2.3rem;
}
.l-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  background: #fff;
}
@media (max-width: 768px) {
  .l-header__content {
    padding: 20px 10px;
    border-bottom: solid 2px #F8F5EC;
  }
}
.l-header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
}
.l-header__logo img {
  width: 100%;
}
@media (max-width: 768px) {
  .l-header__logo {
    width: 55px;
  }
}
.l-header__iconsWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.l-header__iconsWrapper .searchForm {
  display: none;
  width: 94%;
  margin: 8px auto;
  margin-left: 1.5em;
}
@media (max-width: 768px) {
  .l-header__iconsWrapper .searchForm {
    display: none;
  }
}
.l-header__icons {
  display: flex;
  line-height: 1.4;
  align-items: center;
}
@media (max-width: 768px) {
  .active .l-header__icons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1em 5px;
    border-bottom: solid 1px #54391B;
  }
}
.l-header__icons.only-pc {
  display: flex;
  align-items: center;
}
.l-header__icons.only-sp {
  display: none;
}
@media (max-width: 768px) {
  .l-header__icons.only-sp.active {
    display: flex;
    flex-direction: column;
  }
}
.l-header .snsIcons {
  position: relative;
  display: flex;
  padding-right: 1.2em;
  color: #807D7D;
  font-size: 2.8rem;
}
@media (max-width: 1024px) {
  .l-header .snsIcons {
    display: none;
  }
}
.l-header .snsIcons__item {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding: 12px 0;
  border-bottom: 1px solid #fff;
  font-size: 3.8rem;
  line-height: 1;
  max-width: 380px;
}
@media (max-width: 1024px) {
  .l-header .snsIcons__item {
    max-width: 100%;
  }
}
.l-header .snsIcons__item:last-child {
  border-bottom: none;
}
@media (max-width: 1024px) {
  .l-header .snsIcons__item {
    padding: 15px 0;
    margin-top: 5px;
  }
}
.l-header .snsIcons__item .sns_link {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  align-items: center;
}
.l-header .snsIcons__item .sns_link a {
  font-size: 1.3rem;
  width: 100%;
  margin: 5px 0;
}
.l-header .snsIcons::after {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  content: "";
  width: 4px;
  height: 100%;
  background: radial-gradient(circle farthest-side, #807D7D, #807D7D 50%, transparent 50%, transparent);
  background-size: 4px 4px;
}
.l-header .snsIcons.only-sp {
  display: none;
}
@media (max-width: 1024px) {
  .l-header .snsIcons.only-sp {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .l-header .snsIcons.only-sp:after {
    display: none;
  }
  .l-header .snsIcons.only-sp .snsIcons__item {
    padding: 1.5rem;
  }
}
.l-header .LCIcons {
  display: flex;
  color: #807D7D;
  font-size: 2.8rem;
}
.l-header .LCIcons.only-sp {
  display: none;
}
@media (max-width: 640px) {
  .l-header .LCIcons.only-sp {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.l-header .LCIcons__item {
  padding-left: 1em;
  text-align: center;
  position: relative;
}
.l-header .LCIcons__item .icon_txt {
  font-size: 10px;
  display: block;
  color: #7C7C7C;
}
.l-header .LCIcons__item .l-navi__badge .js-lc--cartBadge {
  min-width: 20px;
  height: 20px;
  border-radius: 15px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  position: absolute;
  right: -11px;
  top: -5px;
  display: flex;
  line-height: 1;
  justify-content: center;
  background: #EF1F2E;
  padding: 1px 3px 0 3px;
}
@media (max-width: 640px) {
  .l-header .LCIcons__item .l-navi__badge .js-lc--cartBadge {
    top: 2px;
  }
}
.l-header .LCIcons .icon-search {
  color: #807D7D;
}
.l-header .LCIcons .icon-triangle_down {
  margin-right: -0.8em;
  padding-left: 0.2em;
  font-size: 0.3em;
  vertical-align: middle;
}
.l-header .search__form {
  width: 265px;
  position: relative;
  margin-right: 40px;
}
@media (max-width: 1024px) {
  .l-header .search__form.search__form__head.only_pc {
    display: none;
  }
}
.l-header .search__form input {
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 1px solid #BABABA;
  font-size: 14px;
  border-radius: 0;
  background: transparent;
}
.l-header .search__form input:focus-visible {
  outline: 0;
}
.l-header .search__form input::-webkit-search-cancel-button, .l-header .search__form input::-webkit-search-decoration, .l-header .search__form input::-webkit-search-results-button, .l-header .search__form input::-webkit-search-results-decoration {
  display: none;
}
.l-header .search__form .icon-search {
  right: 0;
  left: auto;
  top: 22%;
  font-size: 20px;
  position: absolute;
  color: #807d7d;
}
.l-header .search__form .icon-search.hide {
  display: none;
}

.login__signup {
  width: 100%;
  max-width: 405px;
  font-size: 16px;
  color: #54391B;
  border: 1px solid #54391B;
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  padding: 13px 25px;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 850px) {
  .login__signup.only_sp {
    display: none;
  }
}
@media screen and (max-width: 849px) {
  .login__signup.only_sp {
    display: block;
    margin: 0 auto;
  }
  .login__signup.only_pc {
    display: none;
  }
}
.login__signup i {
  font-size: 20px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.bg_bc_primary_01 {
  scrollbar-width: thin;
  scrollbar-color: #fff;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 {
    overflow-x: scroll;
  }
}
.bg_bc_primary_01::-webkit-scrollbar {
  height: 3px;
}
.bg_bc_primary_01::-webkit-scrollbar-track {
  background-color: #fff;
}
.bg_bc_primary_01::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide {
    min-width: 100%;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.6s;
  align-items: initial;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp {
  top: 113px;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  padding-bottom: 200px;
  font-size: 1.6rem;
  color: #54391B;
  background-color: #F8F5EC;
  overflow-y: auto;
  z-index: 100;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp {
    top: 95px;
  }
}
@media (max-width: 640px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp {
    top: 81px;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp.active {
  visibility: visible;
  opacity: 1;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp.only_pc .l-navi__item__pc {
  display: none;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu {
  max-width: 1036px;
  margin: 70px auto 0 auto;
  width: 90%;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu {
    margin: 30px auto 0 auto;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List {
  width: 50%;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List {
    width: 100%;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top {
  position: relative;
  font-size: 2.2rem;
  color: #7C7C7C;
  padding-left: 165px;
  margin-bottom: 55px;
  overflow: hidden;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top {
    margin-bottom: 30px;
    padding: 0;
    padding-right: 20px;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 155px;
  height: 1px;
  background: #7C7C7C;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top::before {
    display: none;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #7C7C7C;
  position: absolute;
  top: 50%;
  margin-left: 20px;
  display: none;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .top::after {
    display: inline-block;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner {
  display: flex;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner {
    flex-wrap: wrap;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left,
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right {
  width: 50%;
  display: flex;
  flex-direction: column;
  min-width: 230px;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left,
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right {
    width: 100%;
    min-width: auto;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left a,
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right a {
  font-size: 2.5rem;
  color: #707070;
  font-weight: bold;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left a span,
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right a span {
  display: none;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left a,
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right a {
    font-size: 1.8rem;
    display: block;
  }
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Left a span,
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Inner .hamMenu__List__Right a span {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons {
  margin-top: 6rem;
  display: none;
}
@media (max-width: 1024px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons {
    margin-top: 0;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons p {
  font-size: 1.8rem;
  color: #909090;
  margin-bottom: 25px;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons__list {
  display: flex;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons__list a {
  display: inline-block;
  margin-right: 25px;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List .snsicons__list a i {
  font-size: 32px;
  color: #807d7d;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others {
  width: 50%;
  text-align: center;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others {
    width: 100%;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .search__form {
  width: 100%;
  margin: 90px 0 70px 0;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .search__form input {
  background: transparent;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .menu__list01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .menu__list01 {
    margin-top: 40px;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .menu__list01 a {
  display: inline-block;
  font-size: 14px;
  color: #7C7C7C;
}
.bg_bc_primary_01 .w-extraWide .l-navi.pc_sp .hamMenu .hamMenu__content__List .hamMenu__List__Others .menu__list01 a:last-child {
  margin-right: 0;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item {
  position: relative;
  padding: 15px 0;
  line-height: 1.1;
  cursor: pointer;
  width: 25%;
  text-align: center;
  max-width: 200px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item {
    text-align: center;
    padding: 10px 0;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi__item a, .bg_bc_primary_01 .w-extraWide .l-navi__item p {
  display: inline-block;
  padding: 0 10%;
  border-bottom: solid 1px transparent;
  padding-bottom: 5px;
  position: relative;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item a, .bg_bc_primary_01 .w-extraWide .l-navi__item p {
    padding: 0;
    min-width: 90px;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi__item.down_arrow span::after {
  content: "\e900";
  display: inline-block;
  font-family: "icomoon-lc-casual";
  font-size: 0.8em;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu {
  position: absolute;
  display: none;
  top: 80%;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  min-width: 237px;
  text-align: left;
  z-index: 9;
  padding-top: 30px;
}
@media (max-width: 1448px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item .submenu {
    min-width: 100%;
  }
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item .submenu {
    position: fixed;
    width: 95%;
    min-width: 95%;
    top: 104px;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu .subment_ul {
  border-radius: 15px;
  background: #54391B;
  padding: 35px 30px;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu .subment_ul li {
  margin-bottom: 20px;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu .subment_ul li a {
  display: inline-block;
  color: #fff;
  padding: 0;
  font-weight: normal;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu .subment_ul li a:hover {
  border-bottom: 1px solid #fff;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item .submenu .subment_ul li:last-child {
  margin-bottom: 0;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item:hover > a, .bg_bc_primary_01 .w-extraWide .l-navi__item:hover > p {
  border-bottom: solid 1px #fff;
  opacity: 1;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item:hover > a, .bg_bc_primary_01 .w-extraWide .l-navi__item:hover > p {
    border-bottom: 0;
  }
}
.bg_bc_primary_01 .w-extraWide .l-navi__item.is-current {
  background: #f8f5ec;
  color: #000;
  font-weight: bold;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item.is-current:hover > a, .bg_bc_primary_01 .w-extraWide .l-navi__item.is-current:hover > p {
  border-bottom: 1px solid transparent;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item:hover .submenu {
  display: block;
}
.bg_bc_primary_01 .w-extraWide .l-navi__item.sidebarItem {
  display: none;
}
@media (max-width: 768px) {
  .bg_bc_primary_01 .w-extraWide .l-navi__item.sidebarItem {
    display: block;
  }
}
@media screen and (min-width: 850px) {
  .bg_bc_primary_01 .w-extraWide .l-navi .search__form.only_sp {
    display: none;
  }
}
@media screen and (max-width: 849px) {
  .bg_bc_primary_01 .w-extraWide .l-navi .search__form.only_sp {
    display: block;
    width: 100%;
    max-width: 405px;
    margin: 30px auto 30px auto;
  }
  .bg_bc_primary_01 .w-extraWide .l-navi .search__form.only_pc {
    display: none;
  }
}

.no-scroll {
  overflow-y: hidden;
}

.bg_bc_w {
  background-color: #fff;
}

.bg_bc_primary {
  background-color: #54391B;
}

.bg_bc_primary_light {
  background-color: #F8F5EC;
}

.w-full {
  width: 100%;
}

.w-extraWide {
  max-width: 1920px;
  margin: auto;
}

.w-wide {
  width: calc(100% - 80px);
  max-width: 1286px;
  margin: auto;
}
@media (max-width: 768px) {
  .w-wide {
    width: calc(100% - 20px);
  }
}

.w-middle {
  width: calc(100% - 40px);
  max-width: 1166px;
  margin: auto;
}
@media (max-width: 768px) {
  .w-middle {
    width: calc(100% - 20px);
  }
}

.l-breadcrumbList {
  max-width: 1166px;
  width: calc(100% - 40px);
  margin: 0 auto 3.125em;
  overflow: auto;
}
@media (max-width: 768px) {
  .l-breadcrumbList {
    padding-top: 15px;
    margin-bottom: 1em;
  }
}
@media (max-width: 640px) {
  .l-breadcrumbList {
    padding-top: 15px;
  }
}
.l-breadcrumbList__list {
  display: flex;
  align-items: center;
  padding: 1em 0;
  line-height: 1.3;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .l-breadcrumbList__list {
    padding: 0 0 0.3em;
  }
}
.l-breadcrumbList__item:nth-of-type(n+2)::before {
  font-size: 0.5em;
  font-weight: bold;
  padding: 0 1em;
  content: "\e902";
  font-family: "icomoon-lc-casual";
}

.column {
  display: grid;
}
.column__item:hover a {
  opacity: 1;
}
.column__item:hover a img {
  opacity: 1;
}
.column__item:hover .figure {
  opacity: 1;
  border: solid 1px #54391B;
}
.column__item:hover .name {
  opacity: 1;
}
.column .column__item {
  display: flex;
  flex-direction: column;
}
.column .figure {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: calc(100% - 2px);
  background-color: #fff;
  border: solid 1px transparent;
}
.column .figureBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25em;
}
.column .height_parent {
  flex-grow: 1;
}
.column img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.column .name {
  margin: 1em 0 0;
  font-size: 1.8rem;
  line-height: 1.55;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .column .name {
    font-size: 1.4rem;
    line-height: 1.3;
  }
}
.column .price {
  display: flex;
  margin-bottom: 1em;
  align-items: baseline;
  letter-spacing: 0.1em;
}
.column .price .only-sp {
  display: none;
}
@media (max-width: 768px) {
  .column .price {
    font-size: 1.4rem;
    display: block;
  }
  .column .price .only-sp {
    display: block;
  }
}
.column .sale_price {
  margin-bottom: 0;
  color: red;
}
.column .sale_price span {
  color: #2c2c2c;
}
.column .original_price {
  text-decoration: line-through;
}
.column .amount {
  font-size: 3rem;
}
@media (max-width: 768px) {
  .column .amount {
    font-size: 2rem;
  }
}
.column .currency {
  padding-left: 0.1em;
  font-size: 1.6rem;
}
.column .addToCart {
  margin-bottom: 5em;
}
@media (max-width: 768px) {
  .column .addToCart {
    margin-bottom: 2em;
  }
}
.column .addToCart a,
.column .addToCart button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: solid 1px #54391B;
  color: #54391B;
}
@media (max-width: 768px) {
  .column .addToCart a,
  .column .addToCart button {
    height: 40px;
  }
}
.column .addToCart a::after,
.column .addToCart button::after {
  content: "\e904";
  position: absolute;
  top: 38%;
  right: 1em;
  font-family: "icomoon-lc-casual";
  font-size: 1.4rem;
  line-height: 1;
  display: none;
}
.column .addToCart a:hover,
.column .addToCart button:hover {
  opacity: 1;
  background-color: #54391B;
  color: #fff;
}
.column .addToCart a:disabled,
.column .addToCart button:disabled {
  cursor: not-allowed;
  background: #c2c2c2;
  color: #fff;
  border: 1px solid #c2c2c2;
}
.column .addToCart a:disabled::after,
.column .addToCart button:disabled::after {
  display: none;
}

.seeMore {
  display: flex;
  justify-content: center;
}
.seeMore a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 70px;
  border-radius: 45px;
  border: solid 1px #54391B;
  color: #54391B;
  background-color: #fff;
  box-shadow: 5px 6px 0 #54391B;
  transition: all 0s;
}
@media (max-width: 640px) {
  .seeMore a {
    width: 300px;
    height: 50px;
  }
}
.seeMore a:hover {
  top: 1px;
  left: 1px;
  box-shadow: 0 0 0 #54391B;
  background-color: #54391B;
  color: #fff;
  opacity: 1;
}
.seeMore a span {
  font-size: 2.4rem;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}
@media (max-width: 640px) {
  .seeMore a span {
    font-size: 1.8rem;
  }
}

.js-l-hero .l-hero__item {
  height: auto;
  padding: 0 1%;
}
@media (max-width: 768px) {
  .js-l-hero .l-hero__item {
    padding: 0;
  }
}
.js-l-hero .l-hero__item a {
  display: block;
  height: 100%;
  width: 100%;
}
.js-l-hero .l-hero__item img {
  width: 100%;
  height: 100%;
}

.l-hero img {
  width: 100%;
  height: auto;
}

.slick-prev,
.slick-next {
  width: 70px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 35px;
    height: 45px;
  }
}
.slick-prev::before,
.slick-next::before {
  content: "\e904";
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  font-size: 2.8rem;
  font-family: "icomoon-lc-casual";
  color: #54391B;
}
@media (max-width: 768px) {
  .slick-prev::before,
  .slick-next::before {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: rgba(255, 255, 255, 0.8);
}

.slick-prev {
  left: 0;
  border-radius: 0 45px 45px 0;
}
.slick-prev::before {
  transform: rotate(180deg);
}

.slick-next {
  right: 0;
  border-radius: 45px 0 0 45px;
}

.bg_bc_w .l-contents {
  padding: 7.5em 0;
}
@media (max-width: 768px) {
  .bg_bc_w .l-contents {
    padding: 60px 0;
  }
}
@media (max-width: 640px) {
  .bg_bc_w .l-contents {
    padding: 40px 0;
  }
}
.bg_bc_w .l-contents.pd_pickupItems {
  padding-top: 3em;
}
@media (max-width: 768px) {
  .bg_bc_w .l-contents.pd_pickupItems {
    padding-top: 30px;
  }
}
@media (max-width: 640px) {
  .bg_bc_w .l-contents.pd_pickupItems {
    padding-top: 20px;
  }
}
.bg_bc_primary_light .l-contents {
  padding: 5em 0;
}
@media (max-width: 640px) {
  .bg_bc_primary_light .l-contents {
    padding: 2em 0;
  }
}
.bg_bc_primary_light + .bg_bc_primary_light .l-contents {
  padding-top: 2.5em;
}
.l-contents__title {
  display: flex;
  justify-content: center;
  margin-bottom: 1.58em;
  font-size: 3.8rem;
  background: url(../images/bg_lines.svg) repeat-x;
  background-position: center 55%;
}
@media (max-width: 768px) {
  .l-contents__title {
    font-size: 2.4rem;
    margin-bottom: 40px;
  }
}
@media (max-width: 640px) {
  .l-contents__title {
    margin-bottom: 20px;
  }
}
.l-contents__title span {
  padding: 0 1em;
  display: block;
}

.banners .column {
  column-gap: 2.3%;
  grid-template-columns: repeat(3, minmax(31%, 1fr));
  margin-bottom: 4vw;
}
@media (max-width: 640px) {
  .banners .column {
    grid-template-columns: repeat(2, minmax(48%, 1fr));
  }
}
.banners .column__item a {
  display: block;
  margin-bottom: 2em;
}
.banners .column__item:hover a {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.newItems .column,
.pickupItems .column {
  column-gap: 2.3%;
  grid-template-columns: repeat(5, minmax(18%, 1fr));
}
@media (max-width: 1024px) {
  .newItems .column,
  .pickupItems .column {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }
}
@media (max-width: 640px) {
  .newItems .column,
  .pickupItems .column {
    grid-template-columns: repeat(2, minmax(48%, 1fr));
  }
}

.rankingItems .column {
  column-gap: 2.3%;
  grid-template-columns: repeat(4, minmax(23%, 1fr));
}
@media (max-width: 1024px) {
  .rankingItems .column {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }
}
@media (max-width: 640px) {
  .rankingItems .column {
    grid-template-columns: repeat(2, minmax(48%, 1fr));
  }
}
.rankingItems .column__item:nth-of-type(1) .figure::before {
  background-image: url(../images/icon_ranking_01.svg);
}
.rankingItems .column__item:nth-of-type(2) .figure::before {
  background-image: url(../images/icon_ranking_02.svg);
}
.rankingItems .column__item:nth-of-type(3) .figure::before {
  background-image: url(../images/icon_ranking_03.svg);
}
.rankingItems .column__item:nth-of-type(4) .figure::before {
  background-image: url(../images/icon_ranking_04.svg);
}
.rankingItems .column__item:nth-of-type(5) .figure::before {
  background-image: url(../images/icon_ranking_05.svg);
}
.rankingItems .column__item:nth-of-type(6) .figure::before {
  background-image: url(../images/icon_ranking_06.svg);
}
.rankingItems .column__item:nth-of-type(7) .figure::before {
  background-image: url(../images/icon_ranking_07.svg);
}
.rankingItems .column__item:nth-of-type(8) .figure::before {
  background-image: url(../images/icon_ranking_08.svg);
}
.rankingItems .column .figure::before {
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  z-index: 1;
}

.l-contentsWrap {
  display: grid;
  column-gap: 1fr;
  grid-template-columns: 1fr 5fr;
  width: 1160px;
  max-width: 100%;
}
@media (max-width: 768px) {
  .l-contentsWrap {
    display: block;
  }
}

.archive__topArea {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 3.75em;
}
@media (max-width: 640px) {
  .archive__topArea {
    margin-bottom: 2em;
  }
}
.archive__title {
  margin-right: 0.5em;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  display: inline-block;
  line-height: 1;
}
@media (max-width: 640px) {
  .archive__title {
    font-size: 2.4rem;
  }
}
.archive .number {
  font-size: 1.4rem;
  color: #969696;
  line-height: 1.3;
}
.archive .column {
  column-gap: 2.3%;
  grid-template-columns: repeat(4, minmax(23%, 1fr));
}
@media (max-width: 1024px) {
  .archive .column {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }
}
@media (max-width: 640px) {
  .archive .column {
    grid-template-columns: repeat(2, minmax(140px, 1fr));
  }
}
.archive .column .figure::before {
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  z-index: 1;
}

.controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0;
}
@media (max-width: 1024px) {
  .controller {
    flex-flow: column;
    align-items: flex-start;
    margin: 30px 0;
  }
}
@media (max-width: 1024px) {
  .controller__navi {
    margin-bottom: 10px;
  }
}
.controller__list, .controller__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.controller__item:not(:last-of-type) {
  margin-right: 2em;
}
.controller__label {
  margin-right: 0.2em;
}
.controller__opt select {
  border: none;
  border-bottom: solid 1px #707070;
  padding: 0.2em 0.5em;
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.controller__opt select:active, .controller__opt select:focus {
  outline: 0;
}

.noStock {
  color: red;
}

.pager {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 0;
}
@media (max-width: 640px) {
  .pager {
    margin: 30px 0;
  }
}
.pager a, .pager p, .pager strong {
  display: inline-block;
  width: 30px;
  height: 60px;
  line-height: 60px;
}
@media (max-width: 640px) {
  .pager a, .pager p, .pager strong {
    width: 26px;
    height: 26px;
    height: 30px;
    line-height: 30px;
  }
}
.pager a {
  margin: 0 8px;
}
.pager a:first-of-type, .pager a:last-of-type {
  width: 60px;
  height: 60px;
}
@media (max-width: 640px) {
  .pager a:first-of-type, .pager a:last-of-type {
    width: 30px;
    height: 30px;
    margin: 0 3px;
  }
}
.pager a:first-of-type span, .pager a:last-of-type span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: solid 1px #54391B;
  border-radius: 100%;
  color: #54391B;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 600;
}
@media (max-width: 640px) {
  .pager a:first-of-type span, .pager a:last-of-type span {
    width: 30px;
    height: 30px;
  }
}
.pager strong.current {
  color: #54391B;
  border-radius: 8px;
}

.l-cartInModal {
  display: none;
}
.l-cartInModal.is-active {
  position: fixed;
  top: 0;
  left: 0;
  padding: 3rem;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 99;
  text-align: center;
  display: flex;
}
@media (max-width: 768px) {
  .l-cartInModal.is-active {
    padding: 20px 15px;
  }
}
.l-cartInModal__bg, .l-cartInModal__body {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}
.is-active > .l-cartInModal__bg, .is-active > .l-cartInModal__body {
  opacity: 1;
  visibility: visible;
  padding: 80px 60px;
}
@media screen and (max-width: 800px) {
  .is-active > .l-cartInModal__bg, .is-active > .l-cartInModal__body {
    padding: 60px 30px;
  }
}
@media (max-width: 768px) {
  .is-active > .l-cartInModal__bg, .is-active > .l-cartInModal__body {
    padding: 30px 20px;
  }
}
.l-cartInModal__bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  cursor: pointer;
}
.l-cartInModal__body {
  width: 100%;
  max-width: 819px;
  padding: 0;
  background: #fff;
  z-index: 12;
  border-radius: 10px;
  position: relative;
  max-height: 80vh;
  overflow-y: scroll;
}
.l-cartInModal__body::-webkit-scrollbar {
  display: none;
}
.l-cartInModal .cartIn-btn .btns {
  width: 100%;
}
.l-cartInModal .cartIn-btn .btns .cartin {
  margin-right: 0;
}

.cartInArea {
  position: relative;
}

.l-cartin {
  margin: 0 auto;
}
.l-cartinSku__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.l-cartinSku__title::after {
  content: "";
  display: block;
  flex-grow: 1;
  margin-left: 7px;
  border-top: solid 1px #E0E0E0;
}
.l-cartinSku__list {
  display: flex;
  flex-wrap: wrap;
}
.l-cartinSku__item {
  min-width: 30%;
  margin-right: 10px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 10px;
}
.l-cartinSku__item--image {
  width: 30%;
}
.l-cartinSku__item:nth-child(8n) {
  margin-right: 0;
}
.l-cartinSku__list--text > .l-cartinSku__item {
  width: auto;
}
.l-cartinSku__radio {
  position: absolute;
  opacity: 0.01;
}
.l-cartinSku__radio:disabled ~ * {
  opacity: 0.33;
}
.l-cartinSku__figure {
  padding: 12px 0;
  margin-bottom: 5px;
  border: solid 1px #cbcbcb;
  position: relative;
  transition: 0.3s;
}
:checked + .l-cartinSku__figure {
  background: #cbcbcb;
  color: #000;
}
.l-cartinSku__figure > img {
  display: none;
  max-width: none;
  width: 90%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.l-cartinSku__figure > img.is-portrait {
  width: auto;
  height: 90%;
}
.l-cartinSku__figure.is-loaded > img {
  opacity: 1;
  transition: 0.3s;
}
.l-cartinSku__text {
  font-size: 100%;
  hyphens: auto;
  line-height: 1.3;
  text-align: center;
  word-break: break-word;
}
:checked + .l-cartinSku__text {
  box-shadow: 0 0 0 3px #2c2c2c;
}
.l-cartinSku__button {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 45px;
  min-height: 45px;
  padding: 0 10px;
  border: solid 1px #cbcbcb;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
}
:checked + .l-cartinSku__button {
  background: #cbcbcb;
  color: #000;
}
.l-cartinQuantity {
  display: flex;
  align-items: center;
  margin: 26px 0 0 0;
}
@media (max-width: 1160px) {
  .l-cartinQuantity {
    display: block;
    width: 100%;
  }
}
.l-cartinQuantity__label {
  font-size: 1.6rem;
  margin-right: 1em;
}
.l-cartinQuantity__input, .l-cartinQuantity__button--minus, .l-cartinQuantity__button--plus, .l-cartinQuantity__input[type=text] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  padding: 0 5px;
  border: solid 1px #D9D9D9;
  border-radius: 0;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
}
.l-cartinQuantity__button--minus, .l-cartinQuantity__button--plus {
  width: 40px;
}
.l-cartinQuantity__button--minus {
  border-right: none;
}
.l-cartinQuantity__button--plus {
  border-left: none;
}
.l-cartinOption {
  font-size: 1.6rem;
}
.l-cartinOption__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 15px;
  background: #EFEFEF;
}
.l-cartinOption__item--embroidery {
  display: none;
  background: #FAFAFA;
  margin-bottom: 0;
}
.l-cartinOption__item:not(.l-cartinOption__item--embroidery) + .l-cartinOption__item--embroidery {
  margin-top: -15px;
}
.l-cartinOption__item--embroidery.is-active {
  display: flex;
}
.l-cartinOption__label {
  width: 120px;
}
.l-cartinOption__note {
  width: 100%;
  margin-top: 11px;
  font-size: 1.4rem;
}
.l-cartinOption__pagenote {
  width: 100%;
  margin: 11px 0 30px;
  font-size: 1.4rem;
}
.l-cartinOption__form {
  flex-grow: 1;
  max-width: calc(100% - 120px);
}
.l-cartinOption__select {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border: none;
}
.l-cartin .productActoins__favorite {
  margin-left: 20px;
  font-size: 5rem;
}

.close-button-div {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 32px;
  height: 32px;
}
@media (max-width: 768px) {
  .close-button-div {
    top: 2rem;
    right: 2rem;
    width: 30px;
    height: 30px;
  }
}
.close-button-div .close-button {
  background: none;
  position: relative;
  width: 32px;
  height: 32px;
}
@media (max-width: 768px) {
  .close-button-div .close-button {
    width: 28px;
    height: 28px;
  }
}
.close-button-div .close-button::after, .close-button-div .close-button::before {
  content: "";
  position: absolute;
  top: 1rem;
  right: 0;
  height: 1px;
  background: #000;
  border-radius: 1px;
  transform: rotate(-45deg);
  width: 32px;
}
@media (max-width: 768px) {
  .close-button-div .close-button::after, .close-button-div .close-button::before {
    width: 28px;
  }
}
.close-button-div .close-button::before {
  transform: rotate(45deg);
}

.productDetail .detail_text,
.productDetail .default_image {
  display: none;
}

.modal_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .modal_body {
    display: block;
    text-align: center;
  }
  .modal_body .l-cartinQuantity__form {
    text-align: left;
  }
}
.modal_body .default_image {
  width: 50%;
  text-align: left;
}
@media (max-width: 768px) {
  .modal_body .default_image {
    width: 100%;
    margin: 20px auto 0 auto;
    text-align: center;
  }
}
.modal_body .default_image img {
  width: 80%;
  height: auto;
}
@media (max-width: 768px) {
  .modal_body .default_image img {
    width: 50%;
  }
}
.modal_body .product_detail {
  width: 50%;
}
@media (max-width: 768px) {
  .modal_body .product_detail {
    width: 100%;
  }
}
.modal_body .product_detail .parent_price {
  border-bottom: solid 1px #D9D9D9;
  padding-bottom: 15px;
  margin: 40px 0;
}
@media (max-width: 768px) {
  .modal_body .product_detail .parent_price {
    margin: 10px 0;
    padding-bottom: 10px;
  }
}
.modal_body .detail_text {
  margin-top: 15px;
  text-align: right;
  max-width: 322px;
}
.modal_body .detail_text a {
  display: inline-block;
  padding: 0 0 3px 0;
  border-bottom: 1px solid #2c2c2c;
}
@media (max-width: 1160px) {
  .modal_body .detail_text {
    max-width: 100%;
  }
}
.modal_body .productMainSection__title {
  text-align: left;
}

@media (max-width: 1160px) {
  .productDetail .modal_body {
    display: block;
  }
}
.productDetail .modal_body .l-cartinQuantity {
  margin-bottom: 0;
}

.cartInArea .product_detail {
  width: 100%;
}

@media (max-width: 1160px) {
  .productMainSection .product_detail {
    width: 100%;
  }
}

.productMainSection .productMainSection__header {
  float: right;
  width: calc(100% - 700px);
}
@media (max-width: 768px) {
  .productMainSection .productMainSection__header {
    float: none;
    width: auto;
  }
}
.productMainSection__title {
  margin-bottom: 7px;
  font-size: 2.8rem;
  line-height: 1.25;
  font-weight: bold;
}
.productMainSection__code {
  margin-bottom: 10px;
  color: #666;
  font-size: 1.4rem;
  line-height: 1;
}
.productMainSection__desc {
  margin-bottom: 10px;
}
.productMainSection .productMainSection__desc {
  float: right;
  width: calc(100% - 700px);
}
@media (max-width: 768px) {
  .productMainSection .productMainSection__desc {
    float: none;
    width: auto;
  }
}
.productMainSection__info {
  display: flex;
  gap: 15px;
}
.productMainSection .productMainSection__info {
  float: right;
  width: calc(100% - 700px);
}
@media (max-width: 768px) {
  .productMainSection .productMainSection__info {
    float: none;
    width: auto;
  }
}
.productMainSection__price {
  font-size: 2.4rem;
  line-height: 1;
}
.productMainSection__price small {
  font-size: 1.4rem;
  margin-left: 0.5rem;
  display: inline-block;
  color: #2c2c2c;
}
.productMainSection__price--small {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.productMainSection .productMainSection__price--small {
  float: right;
  width: calc(100% - 700px);
}
@media (max-width: 768px) {
  .productMainSection .productMainSection__price--small {
    float: none;
    width: auto;
  }
}
@media (max-width: 768px) {
  .productMainSection__price--small {
    margin-bottom: 5px;
    position: relative;
    top: -10px;
  }
}
.productMainSection + .contentsInner {
  margin-top: 0;
}
@media (max-width: 1280px) {
  .productMainSection {
    padding: 40px 60px 60px;
  }
}
@media (max-width: 1024px) {
  .productMainSection {
    padding: 40px 15px 30px;
  }
  .productMainSection__header {
    width: auto;
  }
  .productMainSection__info {
    width: 315px;
  }
  .productMainSection__desc {
    width: 315px;
  }
  .productMainSection__price {
    width: auto;
  }
  .productMainSection__price--small {
    width: 315px;
  }
}
@media (max-width: 768px) {
  .productMainSection__header {
    margin-top: 30px;
  }
  .productMainSection__title {
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 2rem;
    line-height: 1.33;
  }
  .productMainSection__code {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  .productMainSection__info {
    width: auto;
  }
  .productMainSection__desc {
    width: auto;
  }
  .productMainSection__price {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
  }
  .productMainSection__price > small {
    font-size: 1.6rem;
  }
  .productMainSection ~ .contentsInner {
    padding-left: 15px;
    padding-right: 15px;
  }
  .productMainSection ~ .contentsInner.lc-reviewsArchive {
    padding: 20px 15px;
  }
}
.productMainSection__info.original_price_div {
  text-decoration: line-through;
  margin-top: 10px;
}
.productMainSection__info.original_price_div .productMainSection__price {
  font-size: 1.4rem;
}
.productMainSection__info.original_price_div .productMainSection__price small {
  margin-left: 0;
  display: initial;
}

.productImages {
  float: left;
  width: 640px;
}
.productImages__main__inner {
  display: block;
  padding-bottom: 100%;
  background: #EFEFEF;
  position: relative;
}
.productImages__main__inner::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.productImages__main__inner > img {
  width: auto !important;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .productMainSection .cartInArea {
    float: none;
    width: auto;
  }
}
.cartInArea__actions {
  display: flex;
}
.cartInArea__actions .lc-button--action {
  flex-grow: 1;
}
.cartInArea__actions .lc-button--onlyIcon {
  flex-grow: 0;
  margin-left: 15px;
}
@media (max-width: 768px) {
  .cartInArea__actions {
    margin-top: 20px;
  }
}

.skuList {
  line-height: 1.25;
}
.skuList__item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  flex-direction: column;
}
.skuList__label {
  flex-shrink: 0;
  width: 100%;
  font-size: 1.6rem;
  position: relative;
  margin-bottom: 20px;
}
.skuList__label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 11px;
  left: 0;
  background-color: green;
  opacity: 0.5;
}
.skuList__label span {
  background-color: pink;
  z-index: 1;
  position: relative;
  padding-right: 10px;
}
.skuList__detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.skuList__detail--size {
  justify-content: flex-start;
  margin: 0 auto 0 0;
}
.skuList__radio {
  display: block;
  margin: 5px 0px 5px 0;
  position: relative;
  width: calc(12.5% - 6px);
}
.skuList__radio--size {
  width: 50px;
  height: 50px;
  border: solid 1px #707070;
  margin-right: 8px;
}
.skuList__radio__input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.01;
}
.skuList__radio__input:checked + .skuList__radio__img {
  padding: 2px 2px;
  border: solid 2px #000;
  color: #000;
}
.skuList__radio__input:disabled + .skuList__radio__img {
  border: solid 1px #D9D9D9;
  background: gray;
  color: #666;
  cursor: default;
}
.skuList__radio__img {
  padding: 2px 2px;
  border-radius: 1px;
  border: solid 1px #666;
  transition: 0.3s;
}
.skuList__radio__button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 0 7px;
  font-size: 1rem;
  border-radius: 100px;
}
.skuList__radio__button--size {
  height: 50px;
  font-size: 1.6rem;
}
.skuList .lc-selectBox__select {
  width: auto;
}
@media (max-width: 768px) {
  .skuList__item {
    margin-bottom: 10px;
  }
  .skuList__label {
    width: 75px;
  }
  .skuList__radio {
    margin: 5px 10px 5px 0;
  }
}

.additionsForm {
  padding: 30px;
  background: #EFEFEF;
}
.additionsForm__title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #666;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
}
.additionsForm__title::before {
  display: inline-block;
  margin-right: 5px;
  font-size: 2.4rem;
}
.additionsForm__item {
  margin-bottom: 15px;
  position: relative;
}
.additionsForm__item:last-child {
  margin-bottom: 0;
}
.additionsForm__input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.01;
}
.additionsForm__input:checked + .additionsForm__box {
  border-color: #000;
}
.additionsForm__input:checked + .additionsForm__box .additionsForm__checkbox {
  border-color: #000;
}
.additionsForm__input:checked + .additionsForm__box .additionsForm__checkbox::before {
  opacity: 1;
  color: #000;
}
.additionsForm__box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border: solid 1px #D9D9D9;
  transition: 0.3s;
  user-select: none;
}
.additionsForm__name {
  display: flex;
  align-items: center;
  line-height: 1.25;
}
.additionsForm__checkbox {
  display: block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  border: solid 1px #2c2c2c;
  position: relative;
  transition: 0.3s;
}
.additionsForm__checkbox::before {
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  left: -1px;
  top: -1px;
}
.additionsForm__detail {
  width: 100%;
  margin-top: 5px;
  padding-top: 7px;
  border-top: dotted 1px #D9D9D9;
  font-size: 1.4rem;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .additionsForm {
    padding: 15px;
  }
  .additionsForm__title {
    margin-bottom: 7px;
    font-size: 1.8rem;
  }
  .additionsForm__item {
    margin-bottom: 10px;
  }
  .additionsForm__detail {
    width: 100%;
    margin-top: 7px;
    padding-top: 10px;
    font-size: 1.2rem;
  }
}

.contentsInner {
  padding: 0 calc(50vw - 580px) 0px;
}
.contentsInner section {
  padding: 0 20px 0 194px;
}
@media (max-width: 768px) {
  .contentsInner section {
    padding: 0;
  }
}
.contentsInner h2 {
  border-top: solid 1px #707070;
  font-size: 2rem;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .contentsInner h2 {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
}
.contentsInner h2 span {
  background-color: #707070;
  color: pink;
  padding: 15px 25px;
  position: relative;
  border-radius: 0 0 20px 0;
  width: 130px;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .contentsInner h2 span {
    padding: 2px 10px;
    position: relative;
    border-radius: 0 0 15px 0;
    width: 80px;
  }
}
.contentsInner h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .contentsInner h3 {
    font-size: 1.4rem;
  }
}
.contentsInner p {
  font-size: 1.4rem;
}
@media (max-width: 768px) {
  .contentsInner p {
    font-size: 1.2rem;
  }
}
.contentsInner ol {
  display: flex;
  margin-top: 30px;
}
.contentsInner ol > li:not([class]) {
  margin-right: 60px;
}
@media (max-width: 768px) {
  .contentsInner ol > li:not([class]) {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .contentsInner ol {
    flex-direction: column;
    margin-top: 20px;
  }
}
.contentsInner ul > li:not([class]) {
  list-style-type: disc;
  margin-left: 20px;
}

.reviewsArchive__number {
  font-size: 1.8rem;
  position: relative;
  top: -4px;
  margin-left: 10px;
}

.reviewStars {
  display: flex;
  width: 6em;
  font-size: 1.4rem;
}
@media (min-width: 769px) {
  .reviewStars.is-changing {
    cursor: pointer;
  }
  .reviewStars.is-changing > span {
    filter: drop-shadow(0 3px 0 rgba(0, 0, 0, 0.25));
  }
}
.reviewStars--large {
  font-size: 2.1rem;
}
.reviewStars--large + .reviewStars__number {
  font-size: 2rem;
}
.reviewStars__product {
  margin: 0 0 0 auto !important;
  top: 1px;
  position: relative;
  font-size: 2rem !important;
}
.reviewStars > span {
  display: block;
  margin-right: 0.25em;
  width: 1em;
  height: 1em;
  background-image: url(../img/icons/review-star-empty.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.reviewStars > span:last-child {
  margin-right: 0;
}
.reviewStars__number {
  margin-left: 10px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .reviewStars--large {
    font-size: 1.9rem;
  }
  .reviewStars--large + .reviewStars__number {
    font-size: 1.8rem;
  }
}

.news_01,
.news_detail {
  max-width: 986px;
}
.news_01 .news_title,
.news_detail .news_title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.news_01 .news_title h1,
.news_detail .news_title h1 {
  font-size: 3.8rem;
  font-weight: bold;
}
.news_01 .news_title span,
.news_detail .news_title span {
  font-size: 16px;
  margin-left: 15px;
  display: inline-block;
}
.news_01 .news_title .see_all,
.news_detail .news_title .see_all {
  position: relative;
  display: inline-block;
  padding-right: 25px;
}
.news_01 .news_title .see_all::after,
.news_detail .news_title .see_all::after {
  content: "\e904";
  position: absolute;
  top: 50%;
  right: 0;
  font-family: "icomoon-lc-casual";
  font-size: 1.4rem;
  line-height: 1;
  transform: translateY(-50%);
}
.news_01 .news_list,
.news_detail .news_list {
  margin-top: 35px;
}
@media (max-width: 768px) {
  .news_01 .news_list,
  .news_detail .news_list {
    margin-top: 20px;
  }
}
.news_01 .news_list li .newPage,
.news_detail .news_list li .newPage {
  display: flex;
  padding: 30px 0;
  border-bottom: solid 1px #bababa;
}
@media (max-width: 768px) {
  .news_01 .news_list li .newPage,
  .news_detail .news_list li .newPage {
    flex-wrap: wrap;
  }
}
.news_01 .news_list li .newPage .date,
.news_detail .news_list li .newPage .date {
  min-width: 180px;
  padding-right: 15px;
  font-size: 14px;
}
@media (max-width: 768px) {
  .news_01 .news_list li .newPage .date,
  .news_detail .news_list li .newPage .date {
    width: 100%;
    margin-bottom: 15px;
  }
}
.news_01 .news_list li .newPage .news_info,
.news_detail .news_list li .newPage .news_info {
  font-size: 16px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 72px;
  overflow: hidden;
}
.news_01 .news_type,
.news_detail .news_type {
  font-size: 12px;
  background: #ec6c01;
  border-radius: 2px;
  padding: 0 4px;
  color: #fff;
  line-height: 1;
  margin-left: 10px;
}
.news_01 .news_type.pink,
.news_detail .news_type.pink {
  background: #E56B8C;
}
.news_01 .news_type.blue,
.news_detail .news_type.blue {
  background: #51A5D9;
}
.news_01 time,
.news_detail time {
  font-size: 14px;
}

.news_detail .l-contentsWrap {
  display: flex;
  justify-content: space-between;
}
.news_detail .l-contentsWrap .archive {
  width: 100%;
}
.news_detail .l-contentsWrap .archive .news_detail_title {
  font-size: 2.6rem;
  margin: 20px 0 50px 0;
  font-weight: bold;
}
.news_detail .l-contentsWrap .archive p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0 0 90px 0;
}
.news_detail .l-contentsWrap .archive h1 {
  font-size: 2.2rem;
  margin: 0 0 40px 0;
  font-weight: bold;
  border-bottom: 2px solid #54391B;
  padding-bottom: 15px;
}
.news_detail .l-contentsWrap .archive h2 {
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: 15px;
  margin: 0 0 40px 0;
  position: relative;
}
.news_detail .l-contentsWrap .archive h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #54391B 50%, #BFBFBF 50%);
}
.news_detail .l-contentsWrap .archive h3 {
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 1px solid #BFBFBF;
  padding-bottom: 15px;
  margin: 0 0 40px 0;
}
.news_detail .l-contentsWrap .sidebar {
  margin-right: 0;
}

.sidebar {
  width: 200px;
  min-width: 200px;
  margin-right: 90px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: left;
}
@media (max-width: 768px) {
  .l-main .sidebar {
    display: none;
  }
}
.sidebar.only_sp {
  display: none;
}
@media (max-width: 1166px) {
  .sidebar {
    margin-right: 40px;
  }
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    margin: 0 auto;
    padding: 0 0 20px 0;
    font-size: 1.6rem;
    background-color: #F8F5EC;
    z-index: 50;
  }
  .sidebar.only_sp {
    display: block;
  }
}
.sidebar__title {
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid #707070;
}
@media (max-width: 768px) {
  .sidebar__title {
    margin-bottom: 1em;
    border-color: #54391B;
  }
}
.sidebar__item--text a {
  display: inline-block;
  padding: 0.5em 0;
}
.sidebar__itemName {
  display: flex;
  padding: 0.5em 0;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .sidebar__itemName {
    font-size: 1.8rem;
  }
}
.sidebar__itemName.has-subList::after {
  content: "\e900";
  display: inline-block;
  font-family: "icomoon-lc-casual";
  font-size: 0.7em;
}
.sidebar__itemName.has-subList.active::after {
  content: "\e903";
}
@media (max-width: 768px) {
  .sidebar__itemName br.only_pc {
    display: none;
  }
}
.sidebar__subList {
  display: none;
  margin: 1em 0;
  padding: 1.5em;
  background-color: #F2F2F2;
  font-size: 1.5rem;
  font-weight: 500;
}
@media (max-width: 768px) {
  .sidebar__subList {
    background-color: #fff;
  }
}
.sidebar__subList a {
  line-height: 2;
}
.sidebar__subItem > a {
  display: block;
  padding: 1em 0;
}

.l-detailsWrap {
  display: flex;
  width: 1280px;
  max-width: 100%;
  margin: auto;
  padding: 0 20px;
}
@media (max-width: 768px) {
  .l-detailsWrap {
    display: block;
  }
}

.l-contentsArea {
  width: 950px;
  max-width: 100%;
}
@media (max-width: 640px) {
  .l-contentsArea {
    width: 100%;
    margin: 10px auto;
    padding: 0;
  }
}

.productDetail {
  display: flex;
  padding-bottom: 60px;
  justify-content: space-around;
  padding-bottom: 110px;
}
@media (max-width: 1160px) {
  .productDetail {
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 640px) {
  .productDetail {
    padding-bottom: 30px;
  }
}
.productDetail__left {
  width: 500px;
  max-width: 100%;
}
@media (max-width: 1366px) {
  .productDetail__left {
    width: 380px;
  }
}
@media (max-width: 640px) {
  .productDetail__left {
    width: 100%;
  }
}
.productDetail__mark {
  margin: 24px 0 40px;
  display: inline-block;
  padding: 20px 32px;
  border: 1px solid #e94e6f;
  border-radius: 15px;
}
@media (max-width: 640px) {
  .productDetail__mark {
    text-align: center;
    padding: 13px 20px;
  }
}
.productDetail__imgwrap {
  position: relative;
  width: 400px;
  height: 400px;
  border: 1px solid #E0E0E0;
}
@media (max-width: 640px) {
  .productDetail__imgwrap {
    width: 100%;
    height: 300px;
    text-align: center;
  }
}
.productDetail__imgwrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}
.productDetail__imgwrap-bg {
  height: 114px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 0;
}
.productDetail__info {
  width: 400px;
  max-width: 100%;
  margin-left: 50px;
}
@media (max-width: 1160px) {
  .productDetail__info {
    width: 100%;
    margin: 24px 0 auto;
  }
}
@media (max-width: 640px) {
  .productDetail__info {
    margin: 0;
  }
}
.productDetail__info-title {
  line-height: 1;
  margin-top: 20px;
}
@media (max-width: 640px) {
  .productDetail__info-title {
    display: block;
  }
}
.productDetail__info-brand {
  width: 100%;
  margin-bottom: 1em;
}
.productDetail__info-name {
  margin-bottom: 0.5em;
  font-size: 2.5rem;
  line-height: 1.4;
}
@media (max-width: 640px) {
  .productDetail__info-name {
    margin: 0;
    font-size: 2.2rem;
  }
}
@media (max-width: 640px) {
  .productDetail__info-code {
    margin: 1.6rem 0 0 0.2rem;
  }
}
.productDetail__info-price {
  font-size: 2.8rem;
  line-height: 1;
  padding-bottom: 0.5em;
  margin: 40px 0;
  border-bottom: 1px solid #E0E0E0;
  letter-spacing: 0.1em;
}
.productDetail__info-price .only-sp {
  display: none;
}
@media (max-width: 768px) {
  .productDetail__info-price .only-sp {
    display: block;
  }
}
@media (max-width: 640px) {
  .productDetail__info-price {
    margin: 20px 0;
    font-size: 2.4rem;
  }
}
.productDetail__info-price .priceTxt p {
  display: inline-block;
}
.productDetail__info-price .priceTxt .guideTxt {
  font-size: 1.4rem;
  margin-top: 1.4rem;
  color: #919191;
  display: block;
}
.productDetail__info-price .priceTxt .guideTxt span {
  margin-left: 0;
  font-size: 1.4rem;
  color: rgba(255, 0, 0, 0.3764705882);
}
.productDetail__info-price .priceTxt .base_price {
  font-size: 1.4rem;
  margin-left: 1.4rem;
  display: inline-block;
}
.productDetail__info-price .priceTxt .title {
  margin-right: 1em;
}
.productDetail__info-price .priceTxt .currency {
  margin-left: 0.2em;
}
.productDetail__info-price .red {
  color: red;
}
.productDetail__info-price .sku_original_price {
  margin-top: 10px;
  font-size: 1.4rem;
}
.productDetail__info-price .sku_original_price .price_01 {
  text-decoration: line-through;
  display: inline-block;
}
.productDetail__info-price .sku_original_price .base_price {
  text-decoration: line-through;
  margin-left: 0;
}
.productDetail__info-desc {
  border-top: 1px solid #E0E0E0;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .productDetail__info-desc {
    margin-bottom: 40px;
  }
}
@media (max-width: 640px) {
  .productDetail__info-desc {
    margin-bottom: 30px;
  }
}
.productDetail__info-desc .catchprase,
.productDetail__info-desc h3 {
  padding: 60px 0 30px;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  line-height: 2;
}
@media (max-width: 640px) {
  .productDetail__info-desc .catchprase,
  .productDetail__info-desc h3 {
    padding: 12px 0;
    font-size: 1.6rem;
  }
}
.productDetail__info-desc .descriptionTxt,
.productDetail__info-desc p {
  padding-bottom: 60px;
  text-align: center;
}
.productDetail__info-desc table {
  width: 100%;
  border-top: 1px solid #E0E0E0;
}
.productDetail__info-desc table tr {
  border-bottom: 1px solid #E0E0E0;
}
.productDetail__info-desc table tr th, .productDetail__info-desc table tr td {
  vertical-align: top;
  padding: 24px 0;
}
@media (max-width: 640px) {
  .productDetail__info-desc table tr th, .productDetail__info-desc table tr td {
    display: block;
    padding: 12px 0;
  }
}
.productDetail__info-desc table tr th {
  padding-right: 2.4rem;
  width: 10%;
  min-width: 8em;
}
@media (max-width: 640px) {
  .productDetail__info-desc table tr th {
    width: 100%;
    padding-bottom: 0;
  }
}
.productDetail__info-desc table tr td {
  width: 70%;
}
@media (max-width: 640px) {
  .productDetail__info-desc table tr td {
    width: 100%;
  }
}
.productDetail__info-desc table ul li,
.productDetail__info-desc table ol li {
  text-indent: -1.2em;
  padding-left: 1.2em;
}
.productDetail__info-desc table ul li + li,
.productDetail__info-desc table ol li + li {
  margin-top: 1em;
}
.productDetail__info-desc table ul li::before,
.productDetail__info-desc table ol li::before {
  content: "・";
}
.productDetail__info-desc table ol {
  counter-reset: heading-counter;
}
.productDetail__info-desc table ol li {
  counter-increment: heading-counter;
}
.productDetail__info-desc table ol li::before {
  content: counter(heading-counter) "、";
}

.productImages {
  width: 100%;
  margin: 0 auto;
}
.productImages .slider-nav {
  margin: 35px auto 0 auto;
}
.productImages .slider-nav .slick-slide {
  margin-right: 8px;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  width: 80px !important;
  height: 80px !important;
  position: relative;
}
.productImages .slider-nav .slick-slide div {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex !important;
  align-items: center;
}
.productImages .slider-nav .slick-slide div img {
  width: 100%;
  height: auto;
}
.productImages .slider-nav .slick-prev, .productImages .slider-nav .slick-next {
  top: 75%;
  transform: translate(0, -50%);
}
.productImages .slider-nav .slick-next {
  right: 0;
}
@media (max-width: 425px) {
  .productImages .slider-nav .slick-next {
    right: 0;
  }
}
@media (max-width: 425px) {
  .productImages .slider-nav .slick-prev {
    left: -5px;
  }
}
.productImages .slider-nav .slick-prev:before {
  content: "\e902";
  display: inline-block;
}
.productImages .slider-nav .slick-next:before {
  content: "\e902";
  display: inline-block;
}

.selectBox {
  display: flex;
  align-items: center;
  margin: 0 10px 25px 0;
}
@media (max-width: 640px) {
  .selectBox {
    font-size: 1.4rem;
  }
}
@media (max-width: 425px) {
  .selectBox {
    margin: 0 0 20px 0;
  }
}
.selectBox__outer {
  position: relative;
}
.selectBox__outer::before {
  content: ">";
  position: absolute;
  transform: rotate(90deg);
  top: 30%;
  right: 20px;
  z-index: 0;
  font-size: 1.8rem;
  line-height: 1;
  font-family: "Noto Sans SC", sans-serif;
}
@media (max-width: 640px) {
  .selectBox__outer {
    width: 100%;
  }
}
.selectBox__lb {
  width: 7em;
}
.selectBox__opt {
  width: 220px;
  max-width: 100%;
  height: 40px;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-family: "Noto Sans SC", sans-serif;
  appearance: none;
  position: relative;
  background: 0;
  text-align: left;
}
@media (max-width: 640px) {
  .selectBox__opt {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .l-cartInModal__body .qty_and_cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .l-cartInModal__body .qty_and_cart .selectBox {
    width: 48%;
    margin: 0;
  }
  .l-cartInModal__body .qty_and_cart .cartIn-btn {
    margin: 0;
    width: 48%;
  }
  .l-cartInModal__body .qty_and_cart .cartIn-btn .btns {
    margin-top: 0;
  }
  .l-cartInModal__body .qty_and_cart .cartIn-btn .btns .cartin {
    min-width: auto;
    height: 50px;
  }
}

.cartIn-btn {
  margin-top: 60px;
}
@media (max-width: 640px) {
  .cartIn-btn {
    margin-top: 30px;
  }
}
.cartIn-btn .cartin {
  position: relative;
  width: 100%;
  height: 60px;
  margin-right: 20px;
  color: #fff;
  background-color: #54391B;
  max-width: 322px;
  cursor: pointer;
  transition: 0.3s ease 0s;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1160px) {
  .cartIn-btn .cartin {
    max-width: 100%;
  }
}
@media (max-width: 640px) {
  .cartIn-btn .cartin {
    min-width: 160px;
  }
}
.cartIn-btn .cartin:hover {
  opacity: 0.7;
}
.cartIn-btn .cartin::after {
  position: absolute;
  top: 50%;
  right: 1em;
  content: "\e904";
  margin-right: 10px;
  font-family: "icomoon-lc-casual";
  transform: translateY(-50%);
  display: none;
}
.cartIn-btn .cartin:disabled {
  cursor: not-allowed;
  background-color: #c2c2c2;
}
.cartIn-btn .cartin:disabled::after {
  display: none;
}
.cartIn-btn .cartin:disabled:hover {
  opacity: 1;
}
.cartIn-btn .btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
@media (max-width: 1160px) {
  .cartIn-btn .btns {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .cartIn-btn .btns {
    margin-top: 10px;
    width: 85%;
  }
}

.favorite {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 6rem;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.3s ease 0s;
  color: #969696;
  line-height: 1;
}
.favorite:hover {
  opacity: 0.7;
}
.favorite.is-active {
  color: #54391B;
}

.l-footer__contents {
  position: relative;
  max-width: 1216px;
  width: calc(100% - 40px);
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 2em 0 1em 0;
  color: #fff;
}
@media (max-width: 1024px) {
  .l-footer__contents {
    padding: 40px 20px 10px;
    display: block;
    width: 100%;
  }
}
.l-footer__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-footer__logo {
  width: 80px;
  max-width: 100%;
  padding: 0 0 20px;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .l-footer__logo {
    width: auto;
    padding: 0 0 40px;
  }
}
@media (max-width: 640px) {
  .l-footer__logo {
    padding: 0 0 20px;
    max-width: 55px;
  }
}
.l-footer__iconsTitle {
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  margin-bottom: 1.5em;
  position: relative;
}
@media (max-width: 1024px) {
  .l-footer__iconsTitle {
    display: none;
  }
}
.l-footer__iconsTitle::before {
  width: 140px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
}
.l-footer__icons {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .l-footer__icons {
    display: block;
    width: auto;
    margin: 0 auto;
  }
}
.l-footer .snsIcons {
  width: auto;
  text-align: right;
  display: none;
}
@media (max-width: 1024px) {
  .l-footer .snsIcons {
    width: auto;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .l-footer .snsIcons {
    width: 100%;
  }
}
.l-footer .snsIcons__list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  font-size: 3.8rem;
  line-height: 1;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .l-footer .snsIcons__list {
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }
}
@media (max-width: 1024px) {
  .l-footer .snsIcons__item {
    margin: 0.8em 0.2em 0.5em;
  }
}

.pageLinks {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 1.3rem;
  width: calc(380px + 1em);
}
@media (max-width: 1024px) {
  .pageLinks {
    max-width: calc(380px + 1em);
    margin: 0 auto;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .pageLinks__item {
    margin: 0.5em;
  }
}

.footerNavi {
  width: 35%;
  margin-left: 3%;
}
@media (max-width: 1024px) {
  .footerNavi {
    display: none;
  }
}
.footerNavi__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  font-size: 1.8rem;
  width: 100%;
  height: 200px;
  border-right: solid 1px #fff;
  line-height: 1.2;
}
.footerNavi__item {
  width: 8em;
  margin-bottom: 1em;
}

.copyright {
  letter-spacing: 0.05em;
  margin-top: 20px;
  text-align: center;
}

.page_top {
  position: absolute;
  top: 20px;
  right: -60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 1346px) {
  .page_top {
    right: 10px;
  }
}
.page_top span {
  font-size: 16px;
  writing-mode: vertical-rl;
}